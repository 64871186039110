<template>
  <div class="fluid">
    <v-row justify="space-between">
      <v-col cols="4" dense>
        <v-treeview
          :active.sync="groupActive"
          :items="groupList"
          :open.sync="groupOpenTree"
          item-children="roles"
          item-key="_id"
          transition
          hoverable
          activatable
          class="text-button"
        >
          <template v-slot:append="{ item }">
            <template v-if="item.group">
              {{  countEmployee(item) }} 人
            </template>
            <template v-else>
              {{  item.roles.length }} 个角色
            </template>

          </template>
          <template v-slot:prepend="{ item }">
            <v-icon v-if="item.roles">supervisor_account</v-icon>
            <v-icon v-else>perm_identity</v-icon>
          </template>
        </v-treeview>

        <v-btn block outlined color="primary" class="mt-2 ma-2" @click="doAction('create_group', selectedGroup)">
          <v-icon left>add</v-icon>增加分组
        </v-btn>

        <v-btn block outlined color="primary" class="mt-2 ma-2" :disabled="!selectedGroup" @click="doAction('create_roles', selectedGroup)">
          <v-icon left>add</v-icon>增加角色
        </v-btn>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
          <v-scroll-y-transition mode="out-in">
            <div
              v-if="!selectedGroup"
              class="title font-weight-light"
              style="align-self: center;"
            >
              选择一个分组或角色
            </div>
            <v-card flat v-else>
              <v-toolbar dense flat>
                <v-toolbar-title>
                  <v-icon class="mr-2" v-if="selectedGroup.roles">supervisor_account</v-icon>
                  <v-icon class="mr-2" v-else>perm_identity</v-icon>
                  {{selectedGroup.name}}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" icon v-if="selectedGroup.isEdit && selectedGroup.group" @click="doAction('edit_roles', selectedGroup)">
                  <v-icon>create</v-icon>
                </v-btn>
                <v-btn class="ma-2" icon v-if="!selectedGroup.isEdit && selectedGroup.type === 1 && !selectedGroup.roles" @click="doAction('edit_permission', selectedGroup)">
                  <v-icon>create</v-icon>
                </v-btn>
                <v-btn class="ma-2" icon v-if="selectedGroup.isEdit && selectedGroup.group" @click="doAction('delete_roles', selectedGroup)">
                  <v-icon>delete</v-icon>
                </v-btn>
                <v-btn class="ma-2" icon v-if="selectedGroup.isEdit && selectedGroup.roles" @click="doAction('edit_group', selectedGroup)">
                  <v-icon>create</v-icon>
                </v-btn>
                <v-btn class="ma-2" icon v-if="selectedGroup.isEdit && selectedGroup.roles" @click="doAction('delete_group', selectedGroup)">
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-toolbar>
              <template v-if="!selectedGroup.group">
                <v-list dense>
                  <v-list-item @click="doAction('open_roles', item)" v-for="(item, i) in selectedGroup.roles" :key="i">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>keyboard_arrow_right</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="doAction('create_roles', selectedGroup)"><v-icon class="ma-2">add</v-icon>增加角色</v-btn>
                </v-card-actions>
              </template>
              <v-container v-else>
                <v-row align="center" justify="start" v-if="selectedGroup.type === 9">
                  <v-subheader>管理员拥有全部权限，且不能修改。</v-subheader>
                </v-row>
                <v-row align="center" justify="start" v-if="selectedGroup.type === 1">
                  <v-subheader>新用户注册默认分组，不能删除。</v-subheader>
                </v-row>
                <v-row align="center" justify="start" v-if="selectedGroup.type !== 9">
                  <v-subheader>启用权限</v-subheader>
                  <v-chip class="ma-1" color="primary" label small text-color="white" v-for="(item, i) in mergeByName(selectedGroup.permission, PERMISSION)" :key="i">
                    <v-icon left small v-text="item.icon"></v-icon>
                    {{item.title}}
                  </v-chip>
                  <v-chip class="ma-1" color="primary" label small text-color="white" v-if="!mergeByName(selectedGroup.permission, PERMISSION).length">
                   无
                  </v-chip>
                </v-row>
                <v-row align="center" justify="start" v-if="selectedGroup.type !== 9">
                  <v-subheader>禁用权限</v-subheader>
                  <v-chip class="ma-1" color="secondary" label small text-color="white" v-for="(item, i) in mergeByName(selectedGroup.permission, PERMISSION, true)" :key="i">
                    <v-icon left small v-text="item.icon"></v-icon>
                    {{item.title}}
                  </v-chip>
                  <v-chip class="ma-1" color="secondary" label small text-color="white" v-if="!mergeByName(selectedGroup.permission, PERMISSION, true).length">
                    无
                  </v-chip>
                </v-row>
              </v-container>
              <v-divider></v-divider>
              <v-data-table
                :headers="headerEmployee"
                :items="accountList"
                :options.sync="employee_options"
                :server-items-length="accountCount"
                :loading="accountLoading"
                :item-class="row_class"
                loading-text="数据读取中... 请稍后"
                :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
                item-key="_id"
                dense
                transition
                v-if="selectedGroup.group"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title><v-icon class="mr-2">groups</v-icon>部门人员</v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:item.personal.name="{ item }">
                  <account-dialogs :account="item._id" v-on:UpdateEmployee="doAction('reload_tree')">{{item.personal.name ? item.personal.name : '*未设置姓名'}}</account-dialogs>
                </template>
              </v-data-table>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
    </v-row>
    <v-dialog v-model="dialogEdit" persistent max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title class="headline">
           {{dialogTitle}}
          </v-card-title>
          <v-card-text v-if="dialogTitle === '新建分组'">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedGroup.name"
                    :rules="[rules.required]"
                    type="text"
                    label="分组名称"
                    hint="请输入分组名称"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="dialogTitle === '编辑分组'">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedGroup.name"
                    :rules="[rules.required]"
                    type="text"
                    label="分组名称"
                    hint="请输入分组名称"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="dialogTitle === '新建角色' || dialogTitle === '编辑角色' || dialogTitle === '编辑权限'">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="editedRole.group"
                    :items="groupList"
                    :rules="[rules.selected]"
                    item-text="name"
                    item-value="_id"
                    label="角色所属分组"
                    :disabled="dialogTitle === '编辑权限'"
                    outlined
                    dense
                  >
                  </v-select>
                  <v-text-field
                    v-model="editedRole.name"
                    :rules="[rules.required]"
                    type="text"
                    label="角色名称"
                    hint="请输入角色名称"
                    :disabled="dialogTitle === '编辑权限'"
                    outlined
                    dense
                  ></v-text-field>
                  <v-select
                    v-model="editedRole.permission"
                    :items="PERMISSION"
                    :rules="[rules.selected]"
                    item-text="title"
                    item-value="name"
                    label="角色包含权限"
                    return-object
                    outlined
                    dense
                    chips
                    multiple
                    counter
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="toggleRoles">
                        <v-list-item-action>
                          <v-icon :color="editedRole.permission.length > 0 ? 'secondary' : ''">
                            {{ selectIcon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            全部权限
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        small
                        class="ma-1"
                        @click="data.select"
                        @click:close="removeRoles(data.item)"
                      >
                        <v-icon small>{{data.item.icon}}</v-icon>
                        {{ data.item.title }}
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogClose">取消</v-btn>
            <v-btn color="primary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import Util from '@/common/util';
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_DEPT_LIST,
  FETCH_GROUP_LIST,
  CREATE_GROUP,
  EDIT_GROUP,
  CREATE_ROLE,
  EDIT_ROLE,
  FETCH_ACCOUNT_LIST, REMOVE_GROUP, REMOVE_ROLE
} from "@/store/actions.type";
export default {
  data() {
    return {
      groupActive:[],
      groupOpenTree: [],
      dialogTitle: '',
      dialogEdit: false,
      valid: true,
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      editedGroup: {
        _id: '',
        name: '',
      },
      defaultGroup: {
        _id: '',
        name: '',
      },
      editedRole: {
        _id: '',
        name: '',
        group: '',
        permission: [],
        service: []
      },
      defaultRole: {
        _id: '',
        name: '',
        group: '',
        permission: [],
        service: []
      },
      headerEmployee: [
        { text: '姓名', value: 'personal.name' },
        { text: '电子邮件', value: 'username' },
        { text: '手机号码', value: 'phone' },
        { text: '员工编号', value: 'employer.employeeNo' },
        { text: '操作', value: 'actions', sortable: false }
      ],
      employee_options: {},
    }
  },
  created() {},
  computed: {
    ...mapGetters(["groupList", "groupLoading", 'accountLoading', 'accountList', 'accountCount']),
    selectedGroup () {
      if (!this.groupActive.length) return undefined

      const _id = this.groupActive[0]
      let result = null

      for (let obj of this.groupList) {
        result = findId(obj, _id)
        if (result) {
          break
        }
      }
      return result
    },
    PERMISSION () {
      return Util.categories('permission')
    },
    selectAllRoles() {
      return this.editedRole.permission.length === this.PERMISSION.length
    },
    selectSomeRoles() {
      return this.editedRole.permission.length > 0 && !this.selectAllRoles
    },
    selectIcon() {
      if (this.selectAllRoles) return 'check_box'
      if (this.selectSomeRoles) return 'indeterminate_check_box'
      return 'check_box_outline_blank'
    }
  },
  watch: {
    dialogClose (val) {
      val || this.dialogClose()
    },
    selectedGroup () {
      if (this.groupActive.length) this.getEmployee({roles: this.groupActive[0]})
    },
    employee_options: {
      handler () {
        this.getEmployee({roles: this.groupActive[0]})
      },
      deep: true,
    },
  },
  mounted() {
    this.getGroupList()
  },
  methods: {
    getGroupList(open = [], active) {
      this.$store.dispatch(FETCH_DEPT_LIST)
      this.$store.dispatch(FETCH_GROUP_LIST)
        .then(() => {
          this.groupOpenTree = open
          this.groupActive = [active]
        })

    },
    doAction(action, item) {
      switch (action) {
        case 'create_group': {
          this.dialogTitle = '新建分组'
          this.dialogEdit = true
          break
        }
        case 'create_roles': {
          if (this.selectedGroup.group) {
            this.editedRole.group = this.selectedGroup.group
          } else {
            this.editedRole.group = this.selectedGroup._id
          }
          this.dialogTitle = '新建角色'
          this.dialogEdit = true
          break
        }
        case 'open_roles': {
          this.groupOpenTree = [item.group, item._id]
          this.groupActive = [item._id]
          break
        }
        case 'edit_roles': {
          this.dialogTitle = '编辑角色'
          this.editedRole['_id'] = item._id
          this.editedRole['name'] = item.name
          this.editedRole['group'] = item.group
          this.editedRole['permission'] = this.mergeByName(item.permission, this.PERMISSION)
          this.dialogEdit = true
          break
        }
        case 'edit_permission': {
          this.dialogTitle = '编辑权限'
          this.editedRole['_id'] = item._id
          this.editedRole['name'] = item.name
          this.editedRole['group'] = item.group
          this.editedRole['permission'] = this.mergeByName(item.permission, this.PERMISSION)
          this.dialogEdit = true
          break
        }
        case 'delete_roles': {
          if (this.accountList && this.accountList.length) return this.$store.commit(SET_ERROR, {msg: '删除失败：请先将账号移出该角色后在删除角色。'});
          if (confirm('确定要删除 '+ item.name +'角色？')) {
            this.$store.dispatch(REMOVE_ROLE, item._id)
              .then(() => {
                this.doAction('reload_tree')
              })
              .catch((err) => {
                this.$store.commit(SET_ERROR, {msg: err.response.data.message});
              })

          }
          break
        }
        case 'edit_group': {
          this.dialogTitle = '编辑分组'
          this.editedGroup['_id'] = item._id
          this.editedGroup['name'] = item.name
          this.dialogEdit = true
          break
        }
        case 'delete_group': {
          if (this.selectedGroup && this.selectedGroup.roles && this.selectedGroup.roles.length) return this.$store.commit(SET_ERROR, {msg: '删除失败：请先将角色移出该分组后在删除分组。'});
          if (confirm('确定要删除 '+ item.name +'分组？')) {
            this.$store.dispatch(REMOVE_GROUP, item._id)
              .then(() => {
                this.doAction('reload_tree')
              })
              .catch((err) => {
                this.$store.commit(SET_ERROR, {msg: err.response.data.message});
              })

          }
          break
        }
        case 'reload_tree': {
          this.getEmployee({roles: this.groupActive[0]});
          this.getGroupList(this.groupOpenTree, this.groupActive[0])
          break
        }
      }
    },
    dialogClose () {
      this.dialogEdit = false
      this.$nextTick(() => {
        this.editedGroup = Object.assign({}, this.defaultGroup)
        this.editedRole = Object.assign({}, this.defaultRole)
      })
    },
    submit() {
      switch (this.dialogTitle) {
        case '新建分组': {
          let strInsert = {}
          strInsert.name = this.editedGroup.name
          this.$store.dispatch(CREATE_GROUP, strInsert)
            .then((data)=> {
              this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
              this.getGroupList([], data._id)
              this.dialogClose()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
        case '新建角色': {
          let arrPermission = this.editedRole.permission.map(item => item.name)
          let strInsert = {}
          strInsert.name = this.editedRole.name
          strInsert.group = this.editedRole.group
          strInsert.permission = arrPermission
          this.$store.dispatch(CREATE_ROLE, strInsert)
            .then((data)=> {
              this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
              this.getGroupList([strInsert.group], data._id)
              this.dialogClose()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
        case '编辑分组': {
          let strUpdate = {}
          strUpdate._id = this.editedGroup._id
          strUpdate.name = this.editedGroup.name
          this.$store.dispatch(EDIT_GROUP, strUpdate)
            .then((data)=> {
              this.$store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
              this.getGroupList([], data._id)
              this.dialogClose()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error});
            })
          break
        }
        case '编辑角色': {
          let arrPermission = this.editedRole.permission.map(item => item.name)
          let strInsert = {}
          strInsert._id = this.editedRole._id
          strInsert.name = this.editedRole.name
          strInsert.group = this.editedRole.group
          strInsert.permission = arrPermission
          this.$store.dispatch(EDIT_ROLE, strInsert)
            .then((data)=> {
              this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
              this.getGroupList([strInsert.group], data._id)
              this.dialogClose()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
        case '编辑权限': {
          let arrPermission = this.editedRole.permission.map(item => item.name)
          let strInsert = {}
          strInsert._id = this.editedRole._id
          strInsert.name = this.editedRole.name
          strInsert.group = this.editedRole.group
          strInsert.permission = arrPermission
          this.$store.dispatch(EDIT_ROLE, strInsert)
            .then((data)=> {
              this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
              this.getGroupList([strInsert.group], data._id)
              this.dialogClose()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
      }
    },
    countEmployee(obj, countKey = 'employeeCount') {
      if (obj.group) {
        return obj[countKey];
      } else {
        return obj.roles.reduce((a,c) => a + c[countKey], 0)
      }
    },
    getEmployee(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.employee_options;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      const { key, ownertype, ownerid, department, roles } = query;
      let urlQuery = '';
      if (ownertype && ownertype.abbr) urlQuery += '&ownertype='+ ownertype.abbr;
      if (department && department !== -1) urlQuery += '&department='+ department;
      if (roles) urlQuery += '&roles='+ roles;
      if (ownerid) urlQuery += '&ownerid='+ ownerid;
      if (key) urlQuery += '&key='+ key;

      if (Object.keys(this.employee_options).length > 0) {
        this.$store.dispatch(FETCH_ACCOUNT_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    toggleRoles() {
      this.$nextTick(() => {
        if (this.selectAllRoles) {
          this.editedRole.permission = []
        } else {
          this.editedRole.permission = this.PERMISSION.slice()
        }
      })
    },
    removeRoles(item) {
      const index = this.editedRole.permission.indexOf(item)
      if (index >= 0) this.editedRole.permission.splice(index, 1)
    },
    mergeByName(arrInput, arrSource, diff = false) {
      if (diff) {
        return arrSource.filter(sourceItem => !arrInput.some(inputItem => (inputItem === sourceItem.name)))
      } else {
        return arrInput.map(inputItem => arrSource.find(sourceItem => sourceItem.name === inputItem))
      }
    },
    row_class(item) {
      if (item.isBlock) {
        return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
      }
    }
  },
  directives: {}
}
function findId(obj, targetId) {
  if (obj._id === targetId) return obj
  if (obj.roles) {
    for (let item of obj.roles) {
      let check = findId(item, targetId)
      if (check) return check
    }
  }
  return null
}
</script>
