var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fluid"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "dense": ""
    }
  }, [_c('v-treeview', {
    staticClass: "text-button",
    attrs: {
      "active": _vm.groupActive,
      "items": _vm.groupList,
      "open": _vm.groupOpenTree,
      "item-children": "roles",
      "item-key": "_id",
      "transition": "",
      "hoverable": "",
      "activatable": ""
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.groupActive = $event;
      },
      "update:open": function updateOpen($event) {
        _vm.groupOpenTree = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.group ? [_vm._v(" " + _vm._s(_vm.countEmployee(item)) + " 人 ")] : [_vm._v(" " + _vm._s(item.roles.length) + " 个角色 ")]];
      }
    }, {
      key: "prepend",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.roles ? _c('v-icon', [_vm._v("supervisor_account")]) : _c('v-icon', [_vm._v("perm_identity")])];
      }
    }])
  }), _c('v-btn', {
    staticClass: "mt-2 ma-2",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_group', _vm.selectedGroup);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("增加分组 ")], 1), _c('v-btn', {
    staticClass: "mt-2 ma-2",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary",
      "disabled": !_vm.selectedGroup
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_roles', _vm.selectedGroup);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("增加角色 ")], 1)], 1), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', [_c('v-scroll-y-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [!_vm.selectedGroup ? _c('div', {
    staticClass: "title font-weight-light",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" 选择一个分组或角色 ")]) : _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar', {
    attrs: {
      "dense": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm.selectedGroup.roles ? _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("supervisor_account")]) : _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("perm_identity")]), _vm._v(" " + _vm._s(_vm.selectedGroup.name) + " ")], 1), _c('v-spacer'), _vm.selectedGroup.isEdit && _vm.selectedGroup.group ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('edit_roles', _vm.selectedGroup);
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e(), !_vm.selectedGroup.isEdit && _vm.selectedGroup.type === 1 && !_vm.selectedGroup.roles ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('edit_permission', _vm.selectedGroup);
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e(), _vm.selectedGroup.isEdit && _vm.selectedGroup.group ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('delete_roles', _vm.selectedGroup);
      }
    }
  }, [_c('v-icon', [_vm._v("delete")])], 1) : _vm._e(), _vm.selectedGroup.isEdit && _vm.selectedGroup.roles ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('edit_group', _vm.selectedGroup);
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e(), _vm.selectedGroup.isEdit && _vm.selectedGroup.roles ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('delete_group', _vm.selectedGroup);
      }
    }
  }, [_c('v-icon', [_vm._v("delete")])], 1) : _vm._e()], 1), !_vm.selectedGroup.group ? [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.selectedGroup.roles, function (item, i) {
    return _c('v-list-item', {
      key: i,
      on: {
        "click": function click($event) {
          return _vm.doAction('open_roles', item);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.name))])], 1), _c('v-list-item-action', [_c('v-icon', [_vm._v("keyboard_arrow_right")])], 1)], 1);
  }), 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_roles', _vm.selectedGroup);
      }
    }
  }, [_c('v-icon', {
    staticClass: "ma-2"
  }, [_vm._v("add")]), _vm._v("增加角色")], 1)], 1)] : _c('v-container', [_vm.selectedGroup.type === 9 ? _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "start"
    }
  }, [_c('v-subheader', [_vm._v("管理员拥有全部权限，且不能修改。")])], 1) : _vm._e(), _vm.selectedGroup.type === 1 ? _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "start"
    }
  }, [_c('v-subheader', [_vm._v("新用户注册默认分组，不能删除。")])], 1) : _vm._e(), _vm.selectedGroup.type !== 9 ? _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "start"
    }
  }, [_c('v-subheader', [_vm._v("启用权限")]), _vm._l(_vm.mergeByName(_vm.selectedGroup.permission, _vm.PERMISSION), function (item, i) {
    return _c('v-chip', {
      key: i,
      staticClass: "ma-1",
      attrs: {
        "color": "primary",
        "label": "",
        "small": "",
        "text-color": "white"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": "",
        "small": ""
      },
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    }), _vm._v(" " + _vm._s(item.title) + " ")], 1);
  }), !_vm.mergeByName(_vm.selectedGroup.permission, _vm.PERMISSION).length ? _c('v-chip', {
    staticClass: "ma-1",
    attrs: {
      "color": "primary",
      "label": "",
      "small": "",
      "text-color": "white"
    }
  }, [_vm._v(" 无 ")]) : _vm._e()], 2) : _vm._e(), _vm.selectedGroup.type !== 9 ? _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "start"
    }
  }, [_c('v-subheader', [_vm._v("禁用权限")]), _vm._l(_vm.mergeByName(_vm.selectedGroup.permission, _vm.PERMISSION, true), function (item, i) {
    return _c('v-chip', {
      key: i,
      staticClass: "ma-1",
      attrs: {
        "color": "secondary",
        "label": "",
        "small": "",
        "text-color": "white"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": "",
        "small": ""
      },
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    }), _vm._v(" " + _vm._s(item.title) + " ")], 1);
  }), !_vm.mergeByName(_vm.selectedGroup.permission, _vm.PERMISSION, true).length ? _c('v-chip', {
    staticClass: "ma-1",
    attrs: {
      "color": "secondary",
      "label": "",
      "small": "",
      "text-color": "white"
    }
  }, [_vm._v(" 无 ")]) : _vm._e()], 2) : _vm._e()], 1), _c('v-divider'), _vm.selectedGroup.group ? _c('v-data-table', {
    attrs: {
      "headers": _vm.headerEmployee,
      "items": _vm.accountList,
      "options": _vm.employee_options,
      "server-items-length": _vm.accountCount,
      "loading": _vm.accountLoading,
      "item-class": _vm.row_class,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "item-key": "_id",
      "dense": "",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.employee_options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v("groups")]), _vm._v("部门人员")], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.personal.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('account-dialogs', {
          attrs: {
            "account": item._id
          },
          on: {
            "UpdateEmployee": function UpdateEmployee($event) {
              return _vm.doAction('reload_tree');
            }
          }
        }, [_vm._v(_vm._s(item.personal.name ? item.personal.name : '*未设置姓名'))])];
      }
    }], null, false, 1046487107)
  }) : _vm._e()], 2)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dialogEdit,
      callback: function callback($$v) {
        _vm.dialogEdit = $$v;
      },
      expression: "dialogEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.dialogTitle) + " ")]), _vm.dialogTitle === '新建分组' ? _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "分组名称",
      "hint": "请输入分组名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedGroup.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedGroup, "name", $$v);
      },
      expression: "editedGroup.name"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.dialogTitle === '编辑分组' ? _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "分组名称",
      "hint": "请输入分组名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedGroup.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedGroup, "name", $$v);
      },
      expression: "editedGroup.name"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.dialogTitle === '新建角色' || _vm.dialogTitle === '编辑角色' || _vm.dialogTitle === '编辑权限' ? _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.groupList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "label": "角色所属分组",
      "disabled": _vm.dialogTitle === '编辑权限',
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedRole.group,
      callback: function callback($$v) {
        _vm.$set(_vm.editedRole, "group", $$v);
      },
      expression: "editedRole.group"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "角色名称",
      "hint": "请输入角色名称",
      "disabled": _vm.dialogTitle === '编辑权限',
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedRole.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedRole, "name", $$v);
      },
      expression: "editedRole.name"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.PERMISSION,
      "rules": [_vm.rules.selected],
      "item-text": "title",
      "item-value": "name",
      "label": "角色包含权限",
      "return-object": "",
      "outlined": "",
      "dense": "",
      "chips": "",
      "multiple": "",
      "counter": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend-item",
      fn: function fn() {
        return [_c('v-list-item', {
          attrs: {
            "ripple": ""
          },
          on: {
            "click": _vm.toggleRoles
          }
        }, [_c('v-list-item-action', [_c('v-icon', {
          attrs: {
            "color": _vm.editedRole.permission.length > 0 ? 'secondary' : ''
          }
        }, [_vm._v(" " + _vm._s(_vm.selectIcon) + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" 全部权限 ")])], 1)], 1), _c('v-divider', {
          staticClass: "mt-2"
        })];
      },
      proxy: true
    }, {
      key: "selection",
      fn: function fn(data) {
        return [_c('v-chip', _vm._b({
          staticClass: "ma-1",
          attrs: {
            "input-value": data.selected,
            "close": "",
            "small": ""
          },
          on: {
            "click": data.select,
            "click:close": function clickClose($event) {
              return _vm.removeRoles(data.item);
            }
          }
        }, 'v-chip', data.attrs, false), [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(_vm._s(data.item.icon))]), _vm._v(" " + _vm._s(data.item.title) + " ")], 1)];
      }
    }], null, false, 4257052812),
    model: {
      value: _vm.editedRole.permission,
      callback: function callback($$v) {
        _vm.$set(_vm.editedRole, "permission", $$v);
      },
      expression: "editedRole.permission"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.dialogClose
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }